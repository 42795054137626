'use client';
import { ErrorPage as GrimmeErrorPage } from '@grimme/components';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    // copied for the app router from the original pages/404.tsx.
    <GrimmeErrorPage
      cancelButtonText={t(`myGRIMME_group_error_page_back`)}
      confirmButtonText={t(`myGRIMME_group_error_dialog_go_to_landing_page`)}
      onCancel={() => router.back()}
      onConfirm={() => router.push('/')}
      paragraph={t(`myGRIMME_group_error_page_paragraph`)}
      title={t(`myGRIMME_group_error_page_title`)}
    />
  );
}
